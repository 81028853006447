import React, { useContext } from "react";
import {tawreedTranslationService} from "../../../../../common/translations";
import {Panel} from "primereact/panel";
import {CssUtils} from "../../../../../helpers/css";
import {ObjectUtils} from "../../../../../helpers/object";
import { GlobalContext } from "../../../../../context";
import { AuthUtils } from "../../../../auth/domain";
import { TawreedActionName } from "../../../../../common/actions";
import { Button } from "primereact/button";
import { ApplyDiscountDialog } from "./order-form/discount-dialog";

export type OrderFormSummaryProps = {
    // controls
    className?: string;
    // data
    returned?: boolean;
    subTotal?: number;
    shippingTotal?: number;
    discount?: number;
    total?: number;
    totalTax?:number;
    grossTotal?: number;
    invoiceTotalDiscount?: number;
    invoiceDiscountType: string | undefined;
    invoiceDiscountValue: number | undefined;
    onApplyDiscount:(discountInfo: any)=> Promise<void>

};

export const OrderFormSummary: React.FC<OrderFormSummaryProps> = (props) => {
    const { auth: {user} } = useContext(GlobalContext)
    const [visible, setVisible] = React.useState(false);
    return (
        <React.Fragment>
             <React.Fragment>
        <Panel header={tawreedTranslationService.translate('lbl_sales_orders_summary')} className={props.className}
        icons={
            AuthUtils.current().authorized(TawreedActionName.OrderApplyDiscount) && <Button
            label={tawreedTranslationService.translate("lbl_sales_orders_applay_discount_button")}
            
            type="button" 
            className={"p-button-text"}
            onClick={() => { setVisible(true)}} />
        }
        >
            <div className="grid ml-2">
                <div className="col-12 md:col-6">
                    <div className="grid">
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_gross_total')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.grossTotal, user?.scale!)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_total_discount')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.discount, user?.scale!)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_sub_total')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.subTotal, user?.scale!)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_total_tax')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.totalTax, user?.scale!)}
                        </div>
                        <hr style={{width: '100%'}}/>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_applay_discount')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.invoiceTotalDiscount, user?.scale!)}
                        </div>
                        {
                            !props.returned &&
                            <React.Fragment>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_sales_orders_shipping_total')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {ObjectUtils.toFixed(props.shippingTotal, user?.scale!)}
                                </div>
                            </React.Fragment>
                        }

                        <hr style={{width: '100%'}}/>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_orders_total')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toFixed(props.total, user?.scale!)}
                        </div>
                    </div>
                </div>
            </div>
            </Panel>{visible &&<ApplyDiscountDialog
            hide={()=>setVisible(false)}
            accept={( discountType: string, discountValue: number)=>{
                setVisible(false);
                props.onApplyDiscount(
                {
                    discountType: discountType,
                    discountValue:discountValue
                })}}
            invoiceDiscountType={props.invoiceDiscountType}
            invoiceDiscountValue={props.invoiceDiscountValue}
            visible={visible}
            />
    }
            </React.Fragment>
            {/* <h6 style={{fontWeight:'bold'}}>{tawreedTranslationService.translate('lbl_sales_orders_summary')}</h6> */}
             
        </React.Fragment>
    );
}
